/* You can add global styles to this file, and also import other style files */
:root {
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Work Sans" !important;
}

:root {
  --animationDuration: 0.5s;
  --animationStyle: ease-in-out;
}

.anim {
  opacity: 1;
  transform: translateY(25px);
  transition: 0.5s ease-in-out;
  transition: var(--animatioDuration) var(--animationStyle);
}

.has-animation {
  opacity: 0;
  transform: translateY(100px);
  transition: 10s ease-in-out;
  transition: var(--animationDuration) var(--animationStyle);
}

.visible {
  transform: translate(0) !important;
  opacity: 1 !important;
}

@-webkit-keyframes image-top-down {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(3px);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes image-top-down {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(5px);
  }

  to {
    transform: translateY(0);
  }
}

@-webkit-keyframes animate-down-top {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes animate-down-top {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@-webkit-keyframes animate-top-down {
  0% {
    opacity: 0;
    transform: translateY(0);
  }

  to {
    opacity: 1;
    transform: translateY(11px);
  }
}

@keyframes animate-top-down {
  0% {
    opacity: 0;
    transform: translateY(0);
  }

  to {
    opacity: 1;
    transform: translateY(11px);
  }
}

@-webkit-keyframes animate-out-down-top {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(9px);
  }
}

@keyframes animate-out-down-top {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(9px);
  }
}

@media (min-width: 800px) {
  .anim {
    opacity: 1 !important;
    transform: translateY(0) !important;
  }
}

#home-balance,
.bullet-icon {
  -webkit-animation: image-top-down 2s infinite forwards;
  animation: image-top-down 2s infinite forwards;
}

@-webkit-keyframes hero-form {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes hero-form {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.anim .headline {
  opacity: 0;
  -webkit-animation: animate-down-top 1s 0.7s forwards;
  animation: animate-down-top 1s 0.7s forwards;
}

.anim .headline-note {
  opacity: 0;
  -webkit-animation: animate-down-top 1s 1s forwards;
  animation: animate-down-top 1s 1s forwards;
}

.anim .buttons {
  opacity: 0;
  -webkit-animation: animate-down-top 1s 1.3s forwards;
  animation: animate-down-top 1s 1.3s forwards;
}

@font-face {
  font-family: "Work Sans";
  src: url("assets/fonts/WorkSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Work Sans Bold";
  src: url("assets/fonts/WorkSans-Bold.ttf") format("truetype");
}

a {
  cursor: pointer;
  text-decoration: none;
}

.bg-gray {
  background: #fcfcfd;
}

::-webkit-scrollbar {
  height: 8px;
  width: 8px;
  border-radius: 12px;
  background: #fcfcfd;
}

::-webkit-scrollbar-thumb {
  background: #c7c7c7;
  border-radius: 2px;
}

#home-header {
  padding: 20px 0;
  position: fixed;
  width: 100%;
  top: 0;
  background: #fff;
  z-index: 1;
}
.page-content {
  margin-top: 85px;
}
#desktop-menu > ul,
.footer-links > ul {
  padding: 0;
  margin: 0;
}

#desktop-menu > ul > li {
  display: inline-block;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  padding-right: 40px;
}

#desktop-menu > ul > li a {
  color: #020410 !important;
}

#desktop-menu > ul > li.live-rate,
.overlay-menu-content a.live-rate {
  background: #f9faff;
  border: 1px solid #ebeaed;
  border-radius: 4px;
  font-size: 15px;
  padding: 0 8px;
}

#desktop-menu > ul > li.live-rate span,
.overlay-menu-content a.live-rate span {
  display: inline-block;
}

#desktop-menu > ul > li.live-rate span:first-child,
.overlay-menu-content a.live-rate span:first-child {
  margin-right: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

#desktop-menu > ul > li.live-rate span:last-child,
.overlay-menu-content a.live-rate span:last-child {
  color: #0abe64;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
}

#home-header-btn-link-sm {
  align-items: center;
  padding: 8px 13px;
  text-align: center;
  background: #1d38e4;
  border-radius: 4px;
  color: white;
  font-size: 14px;
  text-decoration: none;
  font-family: "Work Sans";
}
#home-header-btn-link {
  align-items: center;
  padding: 11px 24px;
  text-align: center;
  background: #1d38e4;
  border-radius: 4px;
  color: white;
  text-decoration: none;
  font-family: "Work Sans";
}

.dropdown {
  position: relative;
}

.dropdown-content {
  display: none;
  margin-top: 15px;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content a {
  font-style: normal;
  font-weight: 600;
  font-size: 14px !important;
  line-height: 16px;
  color: #020410;
}

.overlay-menu {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  background-color: #ffffff;
  overflow-x: hidden;
  transition: 0.5s;
  width: 100%;
}

.overlay-menu-content {
  position: relative;
  top: 100px;
  width: 100%;
  text-align: center;
  margin-top: 30px;
  padding: 0 15px;
}

.overlay-menu a,
.overlay-menu .menu {
  padding: 8px;
  text-decoration: none;
  font-size: 20px;
  color: #020410;
  display: block;
  transition: 0.3s;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 20.0784px;
  line-height: 33px;
  letter-spacing: -0.446187px;
  text-align: left;
}

.overlay-menu .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}

.ui-icon {
  display: inline-block;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  vertical-align: middle;
}

.mt-120 {
  margin-top: 100px;
}

.mt-135 {
  margin-top: 135px;
}

.home#section-1 .headline {
  font-family: "Work Sans Bold";
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 60px;
  color: #020410;
  text-align: left;
  margin-top: 50px;
}

.home#section-1 .headline-note {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 27px;
  letter-spacing: -0.4px;
  color: #020410;
  text-align: left;
}

#home-section-1-bg {
  height: 480px;
  background-image: url("assets/images/bg-home-section-2.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: right;
  border-radius: 32px;
  margin-top: 70px;
}

#home-balance {
  border: 2px dashed #1d38e4;
  border-radius: 24.8123px;
  position: absolute;
  padding: 7px;
}

#home-balance > div {
  background-image: url("assets/images/home-balance-bg.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  width: 336px;
  height: 155.75px;
  padding: 23px;
}

#home-balance div .heading {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 13.4831px !important;
  line-height: 19px;
  color: #23262f;
  opacity: 0.4;
  text-align: left;
}

#home-balance div #balance {
  margin-top: 10px;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 30.6215px;
  line-height: 31px;
  color: #23262f;
  text-align: left;
}

#home-balance div #btc > div:first-child > span:first-child {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 13.4831px;
  line-height: 19px;
  color: #23262f;
  opacity: 0.4;
  margin-right: 10px;
}

#home-balance div #btc > div:first-child > span {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 23px;
  color: #23262f;
}

#home-balance div #btc > div:last-child > span {
  left: 228px;
  top: 112px;
  background: #1d38e4;
  border-radius: 12.1233px;
  padding: 4px 9px;
  color: #ffffff;
  font-size: 12px;
}

#section-2 {
  margin-top: 90px;
}

#section-2 .container .row {
  background: #f7f8ff;
  border-radius: 24px;
}

#section-2 .container .row > div {
  padding: 24px;
  border-radius: 16px;
}
#section-2 .container .row > div.bg-gray {
  background: #fcfcfd;
}

.coin-pair span:first-child {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  margin-right: 10px;
  color: #777e91;
  border-radius: 24px;
}

.coin-pair span:last-child {
  padding: 4px 8px;
  color: #ffffff;
  border-radius: 24px;
}

.coin-pair span:last-child.down {
  background: #ff6838 !important;
}

.coin-pair span:last-child.up {
  background: #58bd7d !important;
}

.coin-value {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #23262f;
}

.coin-value-small {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #23262f;
}

.app-screen {
  width: 388px;
  height: 618px;
  background-image: url("assets/images/app-screenshot.png");
  background-size: contain;
  background-repeat: no-repeat;
}

#section-3 {
  margin-top: 50px;
  text-align: center;
}

#section-3 .headline {
  font-family: "Work Sans Bold";
  font-style: normal;
  font-weight: bold;
  font-size: 34.009px;
  line-height: 59px;
  letter-spacing: -2px;
  color: #020410;
}

#section-3 .headline-note {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 28px;
  letter-spacing: -0.4px;
  color: #020410;
}

#section-3 .heading {
  font-family: "Work Sans Bold";
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 32px;
  letter-spacing: -0.4px;
  color: #020410;
}

#section-3 .heading-note {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 28px;
  letter-spacing: -0.4px;
  color: #020410;
}

#section-4 .headline,
#section-6 .headline {
  font-family: "Work Sans Bold";
  font-style: normal;
  font-weight: bold;
  line-height: 56px;
  letter-spacing: -0.02em;
  color: #23262f;
  font-size: 40px;
  text-align: center;
}

#section-5 {
  background-image: url("assets/images/blue-bg.png");
  background-size: cover;
  margin-top: 70px;
}

#section-5 .headline {
  font-family: "Work Sans Bold";
  font-style: normal;
  font-weight: bold;
  font-size: 38px;
  line-height: 56px;
  letter-spacing: -2px;
  color: #ffffff;
}

#section-6 {
  margin-top: 70px;
}

@keyframes nonStop {
  to {
    transform: translate3d(-100%, 0, 0);
  }
}

.testimonials-section {
  max-width: 100vw;
  overflow: scroll;
  overflow: hidden;
  padding: 25px 0;
}

.testimonial-cards {
  transform: translateZ(0);
  animation: nonStop 60s linear 1s infinite normal none running;
  -webkit-animation: nonStop 60s linear 1s infinite normal none running;
  display: flex;
  margin-top: 55px;
}

.testimonial-cards .t-card {
  min-width: 300px;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  box-shadow: 0 0 25px rgb(0 0 0 / 6%);
  border-radius: 5px;
  font-size: 13px;

  align-items: flex-start;
}

.testitomonial-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-size: cover;
}

.testimonial-cards .card-item {
  padding: 22px 20px;
}

.higlight-card {
  padding: 24px;
  background: rgba(253, 253, 253, 0.09);
  border-radius: 32px;
  /* opacity: 0.11; */
}

.icon-holder-container {
  width: 72px;
}

.higlight-card .icon-holder {
  padding: 24px;
  height: 72px;
  border-radius: 22px;
  text-align: center;
  background: rgba(253, 253, 253, 0.118);
}

.higlight-card .card-headline {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  color: #ffffff;
  display: block;
}

.higlight-card .card-headline-note {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.3px;
  color: #ffffff;
  opacity: 0.5;
  display: block;
}

.app-screenshot-2 {
  width: 350px;
  height: 418px;
  background-image: url("assets/images/app-screenshot-2.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 80px;
}

.page-title {
  font-family: "Work Sans Bold";
  color: #23262f;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  text-align: center;
}

.top-posts {
  padding-top: 30px;
  padding-bottom: 30px;
}

.big-blog-card-image {
  background-size: cover;
  border-radius: 20px;
}

.big-blog-card-image::before {
  content: "";
  display: block;
  padding-top: 100%;
}

.vertical-blog-card a {
  color: #020410;
}

.horizontal-blog-card .blog-card-image,
.vertical-blog-card .blog-card-image {
  width: 100%;
  background-size: cover;
  border-radius: 16px;
}

.horizontal-blog-card .blog-card-image::before,
.vertical-blog-card .blog-card-image::before {
  content: "";
  display: block;
  padding-top: 90%;
}

.big-blog-card .post-title,
.horizontal-blog-card .post-title,
.vertical-blog-card .post-title {
  font-family: "Work Sans Bold";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  padding: 0 5px;
  margin-top: 15px;
}

.big-blog-card .timestamp,
.horizontal-blog-card .timestamp {
  padding: 0 5px;
}

.big-blog-card .short-text,
.horizontal-blog-card .short-text,
.vertical-blog-card .short-text {
  font-family: "Work Sans";
  font-size: 14px;
  margin-top: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  padding: 0 5px;
}

.vertical-blog-card .short-text {
  font-size: 13px;
  padding: 0;
}

.blog-post-title {
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 54px;
  line-height: 64px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #020410;
  margin-top: 130px;
}

.blog-nav ul li {
  list-style-type: none;
  display: inline-block;
  padding: 8px 5px;
}

.blog-nav ul li a {
  margin: 0;
}

.blog-nav ul li:not(:first-child):before {
  border-left: 4px solid rgba(0, 0, 0, 0.3);
}

.blog-nav ul li:not(:first-child):before {
  content: "";
  display: inline-block;
  height: 0;
  width: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 4px solid #aaa;
  font-size: 14px;
  margin-right: 12px;
}

.blog-nav ul li a,
.blog-post-timestamp {
  color: #777e91;
}

.blog-nav ul li a.page-active {
  color: #23262f;
  font-style: normal;
  font-weight: bold;
}

.blog-content img {
  max-height: 470px;
}

.blog-content .heading {
  font-family: "Work Sans Bold";
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 48px;
  letter-spacing: -0.01em;
  color: #23262f;
}

.blog-content .text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #020410;
  margin-top: 50px;
}

.recent-posts {
  margin-top: 150px;
}

.recent-posts .heading {
  font-family: "Work Sans Bold";
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 39px;
  margin-bottom: 30px;
  letter-spacing: -0.02em;
  color: #020410;
}

.about-us .headline {
  font-family: "Work Sans Bold";
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 56px;
  text-align: center;
  letter-spacing: -2px;
  color: #12022f;
  margin-top: 50px;
}

.about-us .headline-note {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.4px;
  margin-top: 30px;
  color: #020410;
}

.about-us .banner {
  background-size: contain;
  background-position: center;
  background-image: url("assets/images/about-us-banner-mobile.png");
  height: 855px;
  background-repeat: no-repeat;
}

.about-us .banner-2 {
  background-size: contain;
  background-position: center;
  background-image: url("assets/images/about-us.png");
  height: 555px;
  background-repeat: no-repeat;
}

.about-us .heading {
  font-style: normal;
  font-weight: bold;
  font-size: 45px;
  line-height: 56px;
  letter-spacing: -2px;
  color: #12022f;
}
.about-us .note {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.355556px;
  color: #12022f;
}

.page .header {
  padding: 140px 0;
  background: #eff1fd;
  text-align: center;
}

.page .h2 {
  font-family: "Work Sans Bold";
}

.page .header .headline {
  font-family: "Work Sans Bold";
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 65px;
  text-align: center;
  letter-spacing: -2px;
  color: #12022f;
}

/* Table */

table thead {
  color: #777e91;
  border-bottom: 1.5px solid #ececec !important;
}

table tr {
  margin: 10px;
  cursor: pointer;
}

table tr:hover {
  background: #f8f8f9;
  border-radius: 12px;
}

.table > :not(:first-child) {
  border-top: 1px solid white !important;
}
.table > :not(caption) > * > * {
  border-bottom-width: 0px;
}

table td {
  padding: 12px !important;
  align-content: center;
  vertical-align: middle;
}

table thead td {
  padding: 15px 20px !important;
}

.td-coin-pair {
  min-width: 250px;
}

.td-coin-pair .long-name {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #23262f;
  margin-left: 15px;
}

.td-coin-pair .short-name {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #b1b5c4;
  margin-left: 10px;
}

td.price {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #23262f;
}

td.up {
  color: #58bd7d;
}

td.down {
  color: #ff6838;
}

.trade-btn {
  border: 2px solid #e6e8ec;
  box-sizing: border-box;
  padding: 12px 16px;
  border-radius: 90px;
  text-decoration: none;
  color: #23262f;
}

.footer .top-section {
  text-align: center;
  margin-top: 100px;
}

.footer .top-section .headline {
  font-family: "Work Sans Bold";
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  letter-spacing: -2px;
  color: #020410;
}

.footer .top-section .headline-note {
  margin-top: 35px;
}

.middle-section .list-headline {
  color: #020410;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.355556px;
}

.middle-section ul {
  padding: 0;
}

.middle-section ul li {
  list-style-type: none;
  margin: 8px 0;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.3px;
  color: #020410;
}

.middle-section ul li a {
  color: #020410;
}
.footer .bottom-section {
  padding: 24px;
  border-top: 1px solid #e7e5ea;
  color: #594d6d;
  font-size: 14px;
}

/* Small screens */

@media (min-width: 576px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 85%;
  }
}

/* Medium screen */

@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 90%;
  }

  .ps-6 {
    padding-left: 6.5%;
  }

  .home#section-1 .headline {
    margin-top: 100px;
    font-size: 46px;
  }
  .overlay-menu-content {
    padding: 0 7.5%;
  }

  .dropdown-content {
    display: none;
    background: #fcfcfd;
    border-radius: 12px;
    position: absolute;
    min-width: 160px;
    z-index: 1;
    margin-top: 5px;
    left: -45px;
  }

  .dropdown-content:after {
    content: "";
    display: block;
    position: absolute;
    left: calc(50% - 10px);
    bottom: 100%;
    width: 0;
    height: 0;
    border-bottom: 10px solid #fcfcfd;
    border-top: 10px solid transparent;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
  }

  .dropdown-content a {
    color: black;
    padding: 12px 0;
    text-decoration: none;
    display: block;
    margin: 0 16px;
  }

  .dropdown-content a:not(:last-child) {
    border-bottom: 1.8px solid #e6e8ec;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }

  #desktop-menu {
    padding-left: 50px;
  }

  .top-posts {
    padding-top: 110px;
  }

  .page-title {
    text-align: left;
  }

  .big-blog-card .post-title {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 40px;
    margin-top: 0;
  }

  .big-blog-card .learn-more,
  .horizontal-blog-card .learn-more {
    padding: 6px 24px;
    text-align: center;
    height: 40px;
    border: 2px solid #020410;
    box-sizing: border-box;
    border-radius: 90px;
    color: #23262f;
    text-decoration: none;
    display: inline-block;
    font-style: normal;
    font-weight: bold;
  }

  .horizontal-blog-card .learn-more {
    margin-top: 10px;
  }

  .big-blog-card .short-text,
  .horizontal-blog-card .short-text {
    font-size: 15px;
    margin-top: 16px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }

  .horizontal-blog-card .short-text {
    font-size: 14px;
    padding: 0 15px;
    margin-top: 10px;
  }

  .horizontal-blog-card .post-footer {
    padding: 0 15px;
  }

  .horizontal-blog-card .post-title {
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 24px;
    color: #23262f;
    padding: 0 15px;
    margin-top: 0;
  }

  .about-us .headline {
    font-size: 50px;
  }

  .about-us .banner {
    background-size: contain;
    background-position: center;
    background-image: url("assets/images/about-us-banner.png");
    height: 533px;
  }

  .page .header .headline {
    font-size: 55px;
  }
}

/* Large screens */

@media (min-width: 992px) {
  #desktop-menu {
    padding-left: 40px;
  }

  #home-section-1-bg {
    background-image: url("assets/images/bg-home-section-1.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
    border-radius: 32px;
    height: 680px;
    margin-top: 0;
    margin-right: -9.7%;
  }

  #home-balance {
    top: 485px;
    left: -180px;
  }

  #section-3 {
    padding-top: 120px;
    text-align: left;
  }

  #section-3 .headline {
    font-size: 43px;
  }

  #section-4 {
    margin-top: 50px;
  }

  #section-4 .headline {
    font-size: 35px;
    text-align: left;
  }

  .td-coin-pair {
    min-width: 180px;
  }

  #section-5 {
    margin-top: 135px;
  }

  .app-screenshot-2 {
    width: 518px;
    height: 518px;
    margin-top: 220px;
  }

  .horizontal-blog-card .blog-card-image {
    width: 220px;
  }
}

/* Extra Large screens */

@media (min-width: 1200px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 86%;
  }
  #desktop-menu {
    padding-left: 90px;
  }

  #home-section-1-bg {
    background-image: url("assets/images/bg-home-section-1.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    background-position-y: 100%;
    border-radius: 32px;
    justify-content: right;
    margin-right: -16.6% !important;
    height: 600px !important;
    height: 550px;
  }

  #home-balance {
    top: 390px;
    left: -160px;
  }
}

/* Large and Extra Large screens */

@media (min-width: 1200px), (min-width: 992px) {
}

/* Medium, Large and Extra Large screens */
@media (min-width: 1200px), (min-width: 992px), (min-width: 768px) {
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #1d38e4;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #1d38e4 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
